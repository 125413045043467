
var d3 = require('d3');

// TODO: path gradients are a bitch

/**
 * Default config.
 */

const defaults = {
    // target element or selector to contain the svg
    target: document.querySelector('#chart'),

    // width of chart
    width: 300,

    // height of chart
    height: 150,

    // margin
    margin: { top: 2, right: 0, bottom: 0, left: 2 },

    // axis padding
    axisPadding: 5,

    // axis tick size
    tickSize: 0,

    // number of x-axis ticks
    xTicks: 5,

    // number of y-axis ticks
    yTicks: 3,

    // nice round values for axis
    nice: true,

    // line interpolation
    interpolate: 'curveBasis',
    stroke: d3.rgb(13, 102, 194, 0.65),
    strokeWidth: 1,
    linearGradient: [{
        id: 'linear',
        colors: [d3.rgb(13, 102, 194, 0.62), d3.rgb(13, 102, 194, 0.145), d3.rgb(13, 102, 194, 0.05)]
    },{
        id: 'red',
        colors: [d3.rgb(230, 0, 0, 0.2), d3.rgb(240, 0, 0, 0.1), d3.rgb(255, 0, 0, 0.05)]
    },
        {
            id: 'green',
            colors: [d3.rgb(13, 168, 139, 0.2), d3.rgb(13, 168, 139, 0.1), d3.rgb(13, 168, 139, 0.05)]
        },
    ],
    areaFillId: '#linear'
}

/**
 * LineChart.
 */

// export default
export default class LineChart {

    /**
     * Construct with the given `config`.
     */

    constructor(config) {
        this.set(config)
        this.init()
    }

    /**
     * Set configuration options.
     */

    set(config) {
        Object.assign(this, defaults, config)
    }

    /**
     * Dimensions without margin.
     */

    dimensions() {
        const { width, height, margin } = this
        const w = width - margin.left - margin.right
        const h = height - margin.top - margin.bottom - 6
        return [w, h]
    }

    /**
     * Initialize the chart.
     */
 
    init() {
        const { target, width, height, margin, } = this
        const { tickSize, xTicks, yTicks } = this
        const [w, h] = this.dimensions()

        this.chart = target
            .attr('width', width)
            .attr('height', height)
            // .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)

        this.x = d3.scaleTime()
            .range([0, w])

        this.y = d3.scaleLinear()
            .range([h,0])
        this.xAxis = d3.axisBottom()
            .scale(this.x)
            .ticks(xTicks)
            .tickPadding(8)
            .tickSize(tickSize)

        this.yAxis = d3.axisLeft()
            .scale(this.y)
            .ticks(yTicks)
            .tickPadding(8)
            .tickSize(tickSize)
        this.line = d3.line()
            .x(d => this.x(d.time))
            .y(d => this.y(d.value))
        //   .curve(d3[interpolate])

    }

    /**
     * Render axis.
     */

    renderAxis(data) {
        const {  x, y, nice } = this
        this.xd = x.domain(d3.extent(data, d => d.time))
        this.yd = y.domain(d3.extent(data, d => d.value))
        if (nice) {
            this.xd.nice()
            this.yd.nice()
        }

        // const c = options.animate
        //     ? chart.transition()
        //     : chart

        // c.select('.x.axis').call(xAxis)
        // c.select('.y.axis').call(yAxis)
    }

    /**
     * Render columns.
     */

    renderCols(data) {
        const { chart, x } = this
        const [, h] = this.dimensions()

        const column = chart.selectAll('.column')
            .data(data)

        // enter
        column.enter().append('rect')
            .attr('class', 'column')

        // update
        column.attr('width', 1)
            .attr('height', h)
            .attr('x', d => x(d.time))
            .attr('y', 0)

        // exit
        column.exit()
            .remove()
    }

    /**
     * Render line.
     */

    renderLine(data) {
        // const chart = this.chart.transition()
        const { line } = this
        const g = this.chart.append('g');
        const [, h] = this.dimensions()
        // const values = data.map(item=>item.value)
        // const min = d3.min(values)
        // const max = d3.max(values)
        g.append('path')
            .attr('class', 'line')
            .attr('fill', 'none') //此处需要将处理是为了折线只显示线条
            .attr('stroke', this.stroke)
            .attr('stroke-width', this.strokeWidth || 1)
            .attr('d', line(data))
            // .x(d=>this.xd(d.time))
            // .y(d=>this.yd(d.value))
        var area = d3.area().x(d => this.xd(d.time))
            .y0(h)
            .y1(d => this.yd(d.value))
        g.append('path')
            .attr('class', 'area')
            .attr('fill', `url(${this.areaFillId})`) //此处需要将处理是为了折线只显示线条
            .datum(data)
            .attr('d', area)
        this.linearGradient.forEach(linear => {
            this.renderGradient(linear)
        });
        
        // hack: fixes order
        // chart.node().appendChild(chart.select('.line').node())
    }

    renderGradient({ id, colors }) {
        var svgDefs = this.chart.append('defs');
        var redGradient = svgDefs.append('linearGradient')
            .attr('id', id)
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '0%')
            .attr('y2', '100%');
        if (Array.isArray(colors)) {
            redGradient.append('stop').attr('offset', '0%').style('stop-color', colors[0])
            redGradient.append('stop').attr('offset', '80%')
                .style('stop-color', colors[1])
            redGradient.append('stop').attr('offset', '100%')
                .style('stop-color', colors[2])
        }

    }
    /**
     * Render the chart against the given `data`.
     */

    render(data, options = {}) {
        this.renderAxis(data, options)
        // this.renderCols(data, options)
        this.renderLine(data, options)
    }

    /**
     * Update the chart against the given `data`.
     */

    update(data) {
        this.render(data, {
            animate: true
        })
    }
}
